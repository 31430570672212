@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Roboto Slab";
  background: linear-gradient(to bottom, #1a1a1a, #2d2d47);
  color: white;
  overflow: hidden;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.question, .tie-breaker, .result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.question {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px; /* Adjust the value to control the roundness */ 
  box-shadow: 0px 0px 25px 25px rgba(0,0,0, 0.7);
  overflow: hidden; /* Ensure the shadow doesn't bleed out */
}

button {
  background: linear-gradient(to right, rgba(75, 0, 130, 0), #4a2b56, rgba(75, 0, 130, 0)); /* Dark purple gradient with fading */
  border: none;
  font-family: "Roboto Slab", serif;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  min-width: 32vw; /* Set a minimum width for the button */
  transition: 0.3s;
  border-radius: 25px;
}

button:hover {
  background-color: #683d7a;
  color: white;
}

.question-list {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.question-list .text {
  flex: 1;
  margin-right: 20px;
}

.question-list .image {
  flex: 1;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@keyframes snowfall {
  0% {
    transform: translateY(-100vh) translateX(0);
  }
  100% {
    transform: translateY(100vh) translateX(100px);
  }
}

.snowflake {
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
  animation: snowfall linear infinite;
}

@media (max-width: 768px) {
  .question-list {
    flex-direction: column;
  }
  .question-list .text,
  .question-list .image {
    margin: 0;
  }
}

.toggle-snowflakes {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0px 4px; /* Reduced padding */
  cursor: pointer;
  font-size: 12px; /* Reduced font size */
  width: auto; /* Let the width adjust based on the content */
  min-width: auto; /* Override the min-width property */
}

.fancy-visuals-text {
  display: block;
  text-align: center;
  color: white;
  margin-top: 5px; /* Adjust as needed */
}

.skip-button {
  opacity: 1;
  transition: opacity 0s;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #3f244b;
  border: 1px solid #ccc;
  padding: 4px 8px; /* Reduced padding */
  border-radius: 4px;
  cursor: pointer;
  font-size: 24px; /* Reduced font size */
  width: auto; /* Let the width adjust based on the content */
  min-width: auto; /* Override the min-width property */
}

.start-screen {
  font-family: Roboto Slab;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.message {
  opacity: 0;
  transition: opacity 2s;
  color: white; /* Ensure text color is white */
}

.fade-in {
  opacity: 1;
}

.pause {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.result-desc {
  font-size: 18px;
}