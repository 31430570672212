/* Importing Garamond font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto Slab", serif;
}
