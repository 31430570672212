.snowflake-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1; /* Set z-index to a value lower than other components */
}

.snowflake {
  position: absolute;
  top: -10%;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
  animation: snowfall linear forwards; /* Removed infinite and added forwards to keep end state */
}

@keyframes snowfall {
  from {
      transform: translateY(-10%);
  }
  to {
      transform: translateY(110%);
  }
}
